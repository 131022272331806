<template>
    <section class="car-info-dashboard">
        <div class="car-info-dashboard__car-container">
            <button class="tracking-unit tracking-unit--right">
                <span class="parameter__icon icon icon_type_circle"> <span class="icon__symbol"><UnitsList_unit_menu_tracking :unit-id="unitId" /></span> </span>
            </button>
            <button class="car-info-dashboard__button car-info-dashboard__button-reefer" @click="$emit('update:showMap',!showMap)">
                <SvgIcon name="reefer" class="icon"/>
            </button>
            <div class="reefer-icon">
                <img :src="require('@/assets/img/reefer-icon/' + carIcon + '.png')" alt="reefer">
            </div>
            <div>
                <h3 class="block-title block-title-center">{{ carTitle }}</h3>
            </div>
        </div>
        <ul class="car-info-dashboard__list">
            <li class="car-info-dashboard__item" v-for="(info, i) in listSensors" :key="i">
                <div class="car-info-dashboard__icon" :class="{'main-power-icon': info.main_power}" >
                    <SvgIcon class="icon icon-color" :class="{'main-icon': info.main_power}" :name="info.icon" />
                    <SvgIcon v-if="info.statusIcon" class="icon icon-badge" :class="info.statusClass || ''" :name="info.statusIcon"  />
                    <span v-if="info.iconText" class="main-power-icon__text">{{info.iconText}}</span>
                </div>
                <div class="car-info-dashboard__text-wrap">
                    <div class="car-info-dashboard__title info-text">
                        {{ $t('Unit.'+info.title)  }}
                    </div>
                    <div CLASS="car-info-dashboard__subtitle block-title">
                        {{info.value}} {{info.units}}
                    </div>
                </div>
            </li>
        </ul>
    </section>
</template>

<script>
import {mapGetters} from "vuex";
import UnitsList_unit_menu_tracking from "../UnitsList_unit_menu_tracking";

export default {
    name: "UnitReefer_info",
    components: {UnitsList_unit_menu_tracking},
    props: {
        'value': {
            type: Object,
            default: () => { return {} }
        },
        showMap: {
            type: Boolean
        }
    },
    data(){
        return {
            carInfo: [
                // { title: 'Ignition', icon: 'unit_dashboard__ignition', value:  'ignitionText'},
                // { title: 'Main Power', icon: 'unit_dashboard__charge', iconText: '100%', value: 'main_power,  main_power: true,},
                // { title: 'Mileage', icon: 'unit_dashboard__mileage', value: 'mileage'},
                // { title: 'Battery Percentage', icon: 'unit_dashboard__battery', value: '' },
                // { title: 'Temperature', icon: 'unit_dashboard__temperature', statusClass: 'danger', statusIcon: 'unit_dashboard__check_danger', value: '' },

                { title: 'Last message',          icon: 'unit_dashboard__massage',  value:'timeAgoInfo'},
                { title: 'Speed',                 icon: 'unit_dashboard__speed',    value: 'speed',     statusIcon: 'unit_dashboard__check',statusClass: 'secondary', },
                { title: 'Status',                icon: 'unit_dashboard__status',   value: 'unitStatus',statusIcon: 'unit_dashboard__online' },
                { title: 'Battery Voltage',       icon: 'refeer__malanka',          value: 'battery'},
                { title: 'External Temperature',  icon: 'reefer__temperatura',      value: 'external_temperature' },
                { title: 'Supply Air Temperature',icon: 'refeer__wind',             value: 'supply_discharge' },
                { title: 'Evaporator Coil',       icon: 'refeer__coil',             value:'evaporator_coil1' },
                { title: 'Fuel',                  icon: 'reefer__fuel',             value: 'fuel_level' },
                { title: 'Set point:',            icon: 'reefer__temperatura',      value: 'temperature_setpoint'},
                { title: 'Mode',                  icon: 'reefer__mode',             value: 'operating_mode' },
            ],
        }
    },
    computed:{
        ...mapGetters([
            "getAppUserUnits",
            "unitsLmsgsByIds",
            "getUnitsReeferTypes",
        ]),
        unit() {
            return this.value
        },
        showMapControl:{
          get(){return this.showMap},
          set(val){return this.$emit('update:showMap',val)}
        },
        unitId(){
            return this.unit.id
        },
        speedUnits(){
            return this.getAppUserUnits.speed
        },
        maxSpeed(){
            return this.unit.max_speed > 0 ? this.unit.max_speed : ''
        },
        unitLmsg(){
            return this.unitsLmsgsByIds[this.unit.id] || {}
        },
        sensors(){
            return this.unitLmsg.sensors || {}
        },
        unitLmsgStatus(){
            return this.unitLmsg.status || {}
        },
        carIcon(){
            return this.getUnitsReeferTypes.find( t => t.reefer_type == this.unit?.reefer_type)?.reefer__icon ||  'reefer'
        },
        carTitle(){
            let list = []
            if(this.unit.name > '') list.push(this.unit.name)
            if(this.unit.model > '') list.push(this.unit.model)
            return  '' + list.join('/')
        },
        ignition(){
            return (this.unitLmsgStatus.ign !== null && this.unitLmsgStatus.ign !== undefined) ? this.unitLmsgStatus.ignition : null
        },
        ignitionText(){
            let value = ''
            if(this.unitLmsgStatus.ign !== null && this.unitLmsgStatus.ign !== undefined) {
                if (this.unitLmsgStatus.ignition === true) value = this.$t('On')
                if (this.unitLmsgStatus.ignition === false) value = this.$t('Off')
            }
            return value
        },
        isParked(){
            return this.unitLmsgStatus.isParked
        },
        isMoving(){
            return this.unitLmsgStatus.isMoving
        },
        speed(){
            return (!this.unitLmsg.speed && this.unitLmsg.speed !== 0) ? '-' :
                (Math.round(this.unitLmsg.speed) + ' ' + this.speedUnits)
        },
        timeAgo(){
            return this.unitLmsg.timeAgo
        },
        timeAgoInfo() {
            let timeAgo = this.timeAgo
            if (!timeAgo && timeAgo !== 0){
                return '-';
            }
            let sign = ''
            if(timeAgo<0){
                sign = '-'
                timeAgo *= -1
            }
            //return timeAgo
            let time = {
                y: Math.floor(timeAgo / (365 * 24 * 60 * 60)),
                d: Math.floor(timeAgo / (24 * 60 * 60)),
                h: Math.floor(timeAgo / (60 * 60)),
                m: Math.floor(timeAgo / (60)),
                s: Math.round(timeAgo / (1)),
            }
            // if(time.y) time = time.y+'y'
            // else
            if(time.d > 9) time = time.d+'d'
            else if(time.h) time = time.h+'h'
            else if(time.m) time = time.m+'m'
            else time = time.s+'s'

            //+'('+new Date(this.unitLmsg.time).toISOString()+')'
            return time ? sign + this.$t('{time} ago', {time}) : ''
        },
        timeAgoClass() {
            let timeAgo = this.timeAgo
            if (!timeAgo && timeAgo !== 0){
                return '';
            }
            //if(timeAgo<0) timeAgo *= -1

            if(timeAgo <= 5*60){
                return 'update_status_online'
            }else
            if(timeAgo <= 15*60){
                return 'update_status_online-recently'
            }else
            if(timeAgo <= 60*60){
                return 'update_status_not-online-recently'
            }else
            {
                return 'update_status_not-online-long-time'
            }
        },
        unitStatus() {
            let status = '-'
            if(this.ignition){
                status = this.$t('Units.movements.Ignition on')
            }else
            if(this.isMoving){
                status = this.$t('Units.movements.Moving')
            }else
            if(this.isParked){
                status = this.$t('Units.movements.Stopped')
            }
            return status
        },
        values(){
            return {
                'timeAgoInfo': this.timeAgoInfo,
                'speed': this.speed,
                'unitStatus': { value: this.unitStatus, statusClass: this.timeAgoClass },
            }
        },
        listSensors(){
            let values = {...this.values, ...this.sensors}
            let listSensors = this.carInfo.map( t => {
                let value = values[t.value];
                if(value === undefined) return null
                if(value === null || (value && value.value === null)) value = '-'
                return (value && value.value !== undefined) ? {...t, ...value } : {...t, value}
            }).filter(item => item)

            while(listSensors.length % 3){
                listSensors.push({title:'Empty'})
            }
            return listSensors
        }
    },
}
</script>

<style scoped>

</style>