<template>
    <ButtonIcon_dual_status
        :button-class="'mtb__-4'"
        :disabled="true"
        :icon="isTracking ? 'common__tracking' : 'common__tracking_disabled'"
        :icons="['common__tracking', 'common__tracking_disabled']"
        :value="isTracking"
        @click="setUnit() && switchTracking()"
    />
</template>
<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";

    export default {
        name: 'UnitsList_unit_menu_tracking',
        props: [
            'unitId',
        ],
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
                "unitTracking",
            ]),
            isTracking(){
                return this.unitTracking === this.unitId
            },
        },
        methods: {
            ...mapMutations([
                "setAllUnitsShowOnMap",
            ]),
            ...mapActions([
                "setUnitTracking",
                "setUnitActive",
                "setMapBoundsByUnits",
            ]),
            switchTracking(){
                this.setTracking(!this.isTracking)
                return true;
            },
            setTracking(isTracking){
                this.setUnitTracking(isTracking ? this.unitId : null);
                return true;
            },
            setUnit(){
                this.setUnitActive(this.unitId)
                return true;
            },
        },
    }
</script>